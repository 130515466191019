<template>
    <div class="wrapper">

        <router-view></router-view>

        <!-- HeaderNav section -->
        <HeaderNav></HeaderNav>

        <!-- Breadcrumb section -->
        <BreadcrumbProduct breadcrumbDetail="Whatever your business, let AI guide your decisions." breadcrumbName="Our Products"></BreadcrumbProduct>

        <!-- Banner section -->
        <BannerTwo></BannerTwo>

        <!-- Footer section -->
        <FooterSection></FooterSection>

    </div>
</template>

<script>
import HeaderNav from '@/components/HeaderNav.vue'
import BannerTwo from '@/components/BannerTwo.vue'
import FooterSection from '@/components/FooterSection.vue'
import BreadcrumbProduct from '@/components/BreadcrumbProduct.vue'

export default {
    components: {
        HeaderNav,
        BannerTwo,
        FooterSection,
        BreadcrumbProduct,
    }
}
</script>
