<template>
    <div class="breadcrumb-area breadcrumb-height" data-bg-image="" :style="{backgroundImage: `url(${ detailBg })`}">
        <div class="container h-100">
            <div class="row h-100">
                <div class="col-lg-12">
                    <div class="breadcrumb-item">
                        <h6 class="breadcrumb-title">{{breadcrumbName}}</h6>
                        <h1 class="breadcrumb-detail">{{breadcrumbDetail}}</h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="display-flex row">
            <div class="col-lg-10 col-md-12">
                <div class="row desktop">
                    <h4 class="col-md-2">Our Sector</h4>
                    <!-- :class="{ 'active' : $route.name === 'Home' }" btn purple-color m-auto -->
                    <!-- :class="[{ 'current-locale': locale === 'en' }, { 'locale': locale === 'es' }]" -->
                    <span @click="filterByCategory('All')" :class="[{ 'btn active m-auto' : category === 'All' }, { 'btn purple-color m-auto' : category !== 'All' }]">All</span>
                    <!-- <span @click="filterByCategory('Healthcare AI')" :class="[{ 'btn active-healthcare m-auto' : category === 'Healthcare AI' }, { 'btn purple-color m-auto' : category !== 'Healthcare AI' }]">Healthcare</span> -->
                    <span @click="filterByCategory('Retail Business AI')" :class="[{ 'btn active m-auto' : category === 'Retail Business AI' }, { 'btn purple-color m-auto' : category !== 'Retail Business AI' }]">Retail Business</span>
                    <span @click="filterByCategory('Transportation AI')" :class="[{ 'btn active-transportation m-auto' : category === 'Transportation AI' }, { 'btn purple-color m-auto' : category !== 'Transportation AI' }]">Transportation</span>
                    <span @click="filterByCategory('Industrial AI')" :class="[{ 'btn active-industrial m-auto' : category === 'Industrial AI' }, { 'btn purple-color m-auto' : category !== 'Industrial AI' }]">Industrial</span>
                    <span @click="filterByCategory('Human Surveillance AI')" :class="[{ 'btn active-surveillance m-auto' : category === 'Human Surveillance AI' }, { 'btn purple-color m-auto' : category !== 'Human Surveillance AI' }]">Human Surveillance</span>
                    <span @click="filterByCategory('Media Data AI')" :class="[{ 'btn active-media m-auto' : category === 'Media Data AI' }, { 'btn purple-color m-auto' : category !== 'Media Data AI' }]">Media Data</span>
                </div>
                <div class="row mobile">
                    <h4 class="col-md-2">Our Sectors</h4>
                    <div class="dropdown d-grid col-sm-12" style="border-color: black;">
                        <button type="button" class="btn dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                            <span class="">{{ category }}</span>
                            <i class="icofont-rounded-down" style=""></i>
                        </button>
                        <ul class="dropdown-menu">
                            <li>
                                <span @click="filterByCategory('All')" class="dropdown-item">All</span>
                            </li>
                            <!-- <li>
                                <span @click="filterByCategory('Healthcare AI')" class="dropdown-item">Healthcare</span>
                            </li> -->
                            <li>
                                <span @click="filterByCategory('Retail Business AI')" class="dropdown-item">Retail Business</span>
                            </li>
                            <li>
                                <span @click="filterByCategory('Transportation AI')" class="dropdown-item">Transportation</span>
                            </li>
                            <li>
                                <span @click="filterByCategory('Industrial AI')" class="dropdown-item">Industrial</span>
                            </li>
                            <li>
                                <span @click="filterByCategory('Human Surveillance AI')" class="dropdown-item">Human Surveillance</span>
                            </li>
                            <li>
                                <span @click="filterByCategory('Media Data AI')" class="dropdown-item">Media Data</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <video-background
        src="images/banner/banner-section-topbot.mp4"
        class="area desktop"
    >
        <div class="blog-area blog-style-1">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="blog-item-wrap row">
                            <div class="col-md-3 col-sm-12 blogg" v-for="(p, index) in product" :key="index">
                                <div class="blog-item">
                                    <router-link :to="`/product-detail/${p.slug}`" class="blog-img">
                                        <img :src="`${p.blogImage}`" :alt="p.alt">
                                    </router-link>
                                    <div class="blog-content">
                                        <span :class="p.class">{{p.category}}</span>
                                        <h2 class="blog-title">
                                            <router-link :to="`/product-detail/${p.slug}`">{{p.title}}</router-link>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div v-if="found === false" class="frame">
                                <img src="images/product/notfound.png" alt="">
                                <!-- <h2>No result found <br> at this time!</h2> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </video-background>
    <!-- { 'btn active m-auto' : category === 'All' } -->
    <video-background
        src="images/banner/banner-section-topbot.mp4"
        :style="product.length >= '5' ? {'height': '2700px'} : product.length >= '3' ? {'height': '1500px'} : {'height': '1300px'}"
        class="area mobile"
    >
        <div class="blog-area blog-style-1">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="blog-item-wrap row">
                            <div class="col-md-4 col-sm-12 blogg" v-for="(p, index) in product" :key="index">
                                <div class="blog-item">
                                    <router-link :to="`/product-detail/${p.slug}`" class="blog-img">
                                        <img :src="`${p.blogImage}`" :alt="p.alt">
                                    </router-link>
                                    <div class="blog-content">
                                        <span :class="p.class">{{p.category}}</span>
                                        <h2 class="blog-title">
                                            <router-link :to="`/product-detail/${p.slug}`">{{p.title}}</router-link>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div v-if="found === false" class="frame">
                                <img src="images/product/notfound.png" alt="">
                                <!-- <h2>No result found <br> at this time!</h2> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </video-background>
</template>

<script>
import VideoBackground from 'vue-responsive-video-background-player'
import products from '../data/product.json'
export default {
    components: { 
        VideoBackground
    },
    props: ['breadcrumbName', 'breadcrumbTitle', 'breadcrumbDetail'],
    data () {
        return {
            introBg: 'images/breadcrumb/bg/bg.png',
            detailBg: 'images/breadcrumb/bg/detail-bg2.png',
            products,
            product: [],
            category: 'All',
            found: true
        }
    },
    mounted() {
        this.product = this.products.products
        // Get Localstorage Category
        let category = localStorage.getItem('category')
        if (category) {
            this.filterByCategory(category)
            // Delete Localstorage Category
            localStorage.removeItem('category')
        }
    },
    methods: {
        filterByCategory(category){
            this.category = category
            if (category === 'All') {
                this.product = this.products.products
                this.found = true
            } else {
                this.product = this.products.products.filter(product => product.category === category)
                if (this.product.length === 0) {
                    this.found = false
                } else {
                    this.found = true
                }
            }
        },
        routeToProductDetail(category) {
            const product = this.products.products.filter(product => product.category === category)
            this.$router.push({
                name: 'product-detail',
                params: {
                    slug: product[0].slug
                }
            })
        }
    }
}
</script>
<style scoped>
.breadcrumb-area {
    background-color: #0D0D2D;
}
.breadcrumb-title {
    color: #ffffff;
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    margin-top: 30px;
}
.breadcrumb-detail {
    color: #ffffff;
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 72px;
    /* or 150% */

    text-align: center;
    letter-spacing: 0.03em;
}
.blog-img img{
    width: 100%;
    height: 300px;
    object-fit: cover;
}
.btn.purple-color {
    background-color: transparent;
    color: #000000;
    border-style: solid;
    border-color: rgb(39, 39, 39);
    font-family: "Gordita";
    width: fit-content;
    font-size: 12px;
    border-radius: 6px;
    padding: 20px 20px;
    line-height: 0px;
}

.btn.active {
    background-color: #CE097C;
    color: #ffffff;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    font-family: "Gordita";
    width: fit-content;
    font-size: 12px;
    border-radius: 6px;
    padding: 20px 20px;
    line-height: 0px;
}

.btn.active-healthcare {
    background-color: #EDAA1E;
    color: #ffffff;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    font-family: "Gordita";
    width: fit-content;
    font-size: 12px;
    border-radius: 6px;
    padding: 20px 20px;
    line-height: 0px;
}

.btn.active-transportation {
    background-color: #27376C;
    color: #ffffff;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    font-family: "Gordita";
    width: fit-content;
    font-size: 12px;
    border-radius: 6px;
    padding: 20px 20px;
    line-height: 0px;
}

.btn.active-industrial {
    background-color: #1F6B82;
    color: #ffffff;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    font-family: "Gordita";
    width: fit-content;
    font-size: 12px;
    border-radius: 6px;
    padding: 20px 20px;
    line-height: 0px;
}

.btn.active-surveillance {
    background-color: #5A051A;
    color: #ffffff;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    font-family: "Gordita";
    width: fit-content;
    font-size: 12px;
    border-radius: 6px;
    padding: 20px 20px;
    line-height: 0px;
}

.btn.active-media {
    background-color: #2b493a;
    color: #ffffff;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    font-family: "Gordita";
    width: fit-content;
    font-size: 12px;
    border-radius: 6px;
    padding: 20px 20px;
    line-height: 0px;
}

.display-flex {
    height: 0px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: -50px;
}

.display-flex div {
    background-color: white;
    padding: 12px 28px;
    z-index: 10;
    border-radius: 12px;
}

.display-flex span {
    padding: 15px 15px;
}

.area{
    height: 1180px;
}
.btn.pink-color {
    background-color: #CE097C;
    color: #ffffff;
    font-family: "Exo 2";
    font-size: 12px;
    border-radius: 6px;
    padding: 12px 8px;
    line-height: 0px;
}
.btn.blue-color {
    background-color: #172A52;
    color: #ffffff;
    font-family: "Exo 2";
    font-size: 12px;
    border-radius: 6px;
    padding: 12px 8px;
    line-height: 0px;
}
.btn.blue-dark-color { 
    background-color: #1F6B82;
    color: #ffffff;
    font-family: "Exo 2";
    font-size: 12px;
    border-radius: 6px;
    padding: 12px 8px;
    line-height: 0px;
}
.btn.brown-color {
    background-color: #5A051A;
    color: #ffffff;
    font-family: "Exo 2";
    font-size: 12px;
    border-radius: 6px;
    padding: 12px 8px;
    line-height: 0px;
}
.blog-item {
    background-color: #FFFFFF;
    border-radius: 15px;
}
.blogg {
    display: flex;
}
.blog-area {
    min-height: fit-content;
}
.blog-content {
    padding: 12px 8px;
    border-radius: 15px;
}
.blog-title {
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
}
.frame h2{
    text-align: center;
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    /* or 150% */

    text-align: center;
    letter-spacing: 0.03em;

    color: #202020;
}
.frame img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 600px;
    height: auto;
}

.mobile {
    display: none;
}

@media only screen and (max-width: 991px) {
    .breadcrumb-title {
        font-size: 16px;
    }

    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }

    .area {
        height: 1180px;
    }

    .breadcrumb-height {
        height: 550px;
    }

    .display-flex {
        height: 0px;
        width: 90%;
        display: flex;
        margin-top: -40px;
        margin-left: 5%;
        margin-right: 5%;
    }
    
    .display-flex div {
        text-align: center;
        background-color: white;
        padding: 12px 18px;
        z-index: 10;
        border-radius: 12px;
    }
    
    .display-flex img {
        width: 30%;
        padding: 5px 5px;
    }

    .dropdown {
        font-family: 'Exo 2';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 24px */

        color: #202020;
        border-color: #000000;
        padding: 12px 18px;
        border-radius: 12px;
        text-align: left;
    }
    .btn.dropdown i{
        float: right;
        margin-top: 3px;
    }
    .blogg {
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .frame {
        margin-top: 140px;
    }
}
</style>
